import React, { useMemo, useState } from "react";
import { useOidcContext } from "modules/account/services/oidc-context";
import { useLocalStorage } from "hcss-hooks";
import { useSelector, useDispatch } from "react-redux";
import {
  useHcssUser,
  usePreferences,
  selectors as accountSelectors,
  usePermissions,
  useAuthorization
} from "modules/account";
import { strings, regionOptions, languageOptions } from "localization";
import { actions } from "modules/settings";
import { UnifiedNavReact, UnifiedNavOptions } from "@hcss/unified-platform";
import appconfig from "config";
import {
  isSandboxEnabled,
  enableSandboxMode,
  disableSandbox,
  resetSandbox
} from "core/util";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";
import { FeatureFlag } from "core";
import { useHistory, useLocation } from "react-router-dom";
import {
  useHcssConnectContext,
  NylasAuthenticationComponent
} from "core/services/hcss-connect";

export const UnifiedNav = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const path = useLocation().pathname;

  const user = useHcssUser();
  const permissions = usePermissions();
  const auth = useAuthorization();

  const isCompanyNameValidForSandbox =
    user?.companyName &&
    (user.companyName.toLowerCase().includes("hcss") ||
      user.companyName.toLowerCase().includes("ugm"));

  const { oidcService } = useOidcContext();

  const sandboxEnabled = isSandboxEnabled();

  const homeBU = useSelector(accountSelectors.getHomeBusinessUnit);
  const [_, setLocalStorageBU] = useLocalStorage<{
    id: string | undefined;
    code: string | undefined;
  }>(`user.business.unit-${user?.hcssUserId}`, homeBU);

  const { nylasAccounts, loading, loadAccounts } = useHcssConnectContext();

  const calendarSubtext = useMemo(() => {
    if (loading) return strings.layout.profile.loading;
    if (nylasAccounts.length === 0) return strings.layout.profile.none;
    if (nylasAccounts.length === 1) return nylasAccounts[0].emailAddress;
    return strings
      .formatString(strings.layout.profile.numCalendars, nylasAccounts.length)
      .toString();
  }, [loading, nylasAccounts]);

  const [showNylasAuth, setShowNylasAuth] = useState(false);
  const openNylasAuth = () => {
    setShowNylasAuth(true);
  };
  const closeNylasAuth = async () => {
    await loadAccounts();
    setShowNylasAuth(false);
  };

  const { isFeatureEnabled: isLanguageEnabled } = useFeatureFlags(
    FeatureFlag.LanguageSelector
  );

  const userPreferences = usePreferences();
  const region = useMemo(
    () =>
      regionOptions.find(
        option =>
          option.value ===
          (userPreferences.region ? userPreferences.region : "en-us")
      ),
    [userPreferences.region]
  );
  const language = useMemo(
    () =>
      languageOptions.find(
        option =>
          option.value ===
          (userPreferences.language ? userPreferences.language : "en")
      ),
    [userPreferences.language]
  );

  const settingsMenuAdditionalSections = useMemo(() => {
    const sections: UnifiedNavOptions["settingsMenuAdditionalSections"] = [];
    const settingsItems = [];
    if (permissions.admin && auth.canAccessFullPrecon) {
      if (!sandboxEnabled) {
        settingsItems.push({
          id: "company-profile",
          label: strings.layout.menu.settings.companyProfile,
          href: "/settings/profile",
          onClick: () => history.replace("/settings/profile")
        });
      }
      settingsItems.push({
        id: "user-permissions",
        label: strings.layout.menu.settings.userPermissions,
        href: "/settings/permissions",
        onClick: () => history.replace("/settings/permissions")
      });
    }
    if (settingsItems.length > 0) {
      sections.push({
        id: "precon-settings",
        label: strings.layout.menu.settingsLabel,
        items: settingsItems
      });
    }
    const toolsItems = [];
    if (isCompanyNameValidForSandbox || sandboxEnabled) {
      toolsItems.push({
        id: "toggle-sandbox",
        label: sandboxEnabled
          ? strings.layout.profile.disableSandbox
          : strings.layout.profile.enableSandbox,
        onClick: () => (sandboxEnabled ? disableSandbox() : enableSandboxMode())
      });
    }
    if (sandboxEnabled) {
      toolsItems.push({
        id: "reset-sandbox",
        label: strings.layout.profile.resetSandbox,
        onClick: () => resetSandbox()
      });
    }
    if (toolsItems.length > 0) {
      sections.push({
        id: "precon-tools",
        label: strings.layout.menu.toolsLabel,
        items: toolsItems
      });
    }
    return sections;
  }, [
    auth.canAccessFullPrecon,
    history,
    isCompanyNameValidForSandbox,
    permissions.admin,
    sandboxEnabled
  ]);

  const userMenuAdditionalItems = useMemo(() => {
    const items: UnifiedNavOptions["userMenuAdditionalItems"] = [];
    if (auth.canAccessHcssConnect) {
      items.push({
        id: "connected-calendars",
        label: strings.layout.profile.connectedCalendars,
        subtext: calendarSubtext,
        showExpandIcon: true,
        onClick: openNylasAuth
      });
    }
    items.push({
      id: "region-select",
      label: strings.layout.profile.dateTimeFormat,
      subtext: region?.label,
      showExpandIcon: true,
      onClick: () => dispatch(actions.toggleRegionPanel())
    });
    if (isLanguageEnabled) {
      items.push({
        id: "language-select",
        label: strings.layout.profile.language,
        subtext: language?.label,
        showExpandIcon: true,
        onClick: () => dispatch(actions.toggleLanguagePanel())
      });
    }
    return items;
  }, [
    auth.canAccessHcssConnect,
    region?.label,
    isLanguageEnabled,
    calendarSubtext,
    dispatch,
    language?.label
  ]);

  const product = useMemo(() => {
    if (path.startsWith("/estimates")) return "estimateInsights";
    if (path.startsWith("/contacts")) return "contacts";
    return "projects";
  }, [path]);

  const hasFenixAccess = useFeatureFlags("Fenix").isFeatureEnabled;

  const props = useMemo(() => {
    const unifiedNavProps: UnifiedNavOptions = {
      product: product,
      currentUser: {
        userId: sandboxEnabled ? "" : user.hcssUserId,
        firstName: user.firstName,
        lastName: user.lastName,
        companyId: sandboxEnabled ? "" : user.companyId,
        companyName: user.companyName,
        businessUnitCode: user.selectedBusinessUnitName,
        businessUnitId: user.selectedBusinessUnitId
      },
      callbacks: {
        onLogOut: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          oidcService.signoutRedirect();
        },
        onBusinessUnitChange: newBu => {
          const businessUnit = {
            id: newBu.id,
            code: newBu.code
          };
          setLocalStorageBU(businessUnit);
          window.location.reload();
        }
      },
      settingsMenuAdditionalSections: settingsMenuAdditionalSections,
      userMenuAdditionalItems: userMenuAdditionalItems,
      productOverrides: {
        projects: {
          href: "/projects",
          onClick: () => history.replace("/projects")
        },
        estimates: {
          hasAccess: hasFenixAccess && !sandboxEnabled
        },
        estimateInsights: {
          href: "/estimates",
          onClick: () => history.replace("/estimates")
        },
        contacts: {
          href: "/contacts",
          onClick: () => history.replace("/contacts")
        }
      }
    };

    return unifiedNavProps;
  }, [
    product,
    sandboxEnabled,
    user.hcssUserId,
    user.firstName,
    user.lastName,
    user.companyId,
    user.companyName,
    user.selectedBusinessUnitName,
    user.selectedBusinessUnitId,
    settingsMenuAdditionalSections,
    userMenuAdditionalItems,
    hasFenixAccess,
    oidcService,
    setLocalStorageBU,
    history
  ]);

  return (
    <UnifiedNavReact
      src={`${appconfig.endpoints.UNIFIEDPLATFORM}/components/v1/unified-nav/index.es.js`}
      {...props}
    >
      <NylasAuthenticationComponent
        onHide={closeNylasAuth}
        show={showNylasAuth}
      />
    </UnifiedNavReact>
  );
};
